import { WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { FormAddDebitCard } from "../../../../shared/components/FormAddDebitCard";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { RouteComponentProps } from "../../types";

export const RouteInstantNewCard: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <LayoutPaymentsSetup
      title="Connect your debit card for instant payouts"
      onBack={onBack}
    >
      <WSText mb="XL">
        Instantly receive funds to a supported debit card as soon as the funds
        are received. Income typically appear in the associated bank account
        within 30 minutes.
      </WSText>
      <WSText mb="XL">
        Instant payouts cost 1% of the payout amount, with a minimum fee of
        $0.50.
      </WSText>
      <WSText>
        Most U.S. banks issue debit cards are compatible with Instant Payouts.
      </WSText>

      <FormAddDebitCard setInstantPayoutPreference onSuccess={onNext} />
    </LayoutPaymentsSetup>
  );
};
